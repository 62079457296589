<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- start data table -->
    <b-card title="orders" class="w-100">
      <b-row>
        <!-- sorting Status -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Status"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              :dropup="true"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="status"
              class="border mr-0"
              size="sm"
              :text="statusName"
              variant="flat-primary"
            >
              <div style="overflow-y: scroll; max-height: 350px">
                <b-dropdown-item @click="changeStatus('All', 'all')">
                  All
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeStatus(item.name, item.slug)"
                  v-for="(item, index) in orderStatus"
                  :key="index"
                >
                  {{ item.name }}
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- Classification -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Sales Channel"
            label-cols-sm="5"
            label-align-sm="center"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="classification"
              class="border mr-0"
              size="sm"
              :text="classificationName"
              variant="flat-primary"
            >
              <!-- <div style="max-height: 350px;"> -->
              <b-dropdown-item @click="changeClassification('All', 'all')">
                All
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeClassification(item.name, item.slug)"
                v-for="(item, index) in orderClassification"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
              <!-- </div> -->
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              ></b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class=""
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" />
                  Download Excel
                </b-dropdown-item>
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" />
                Save PDF
              </b-dropdown-item>
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>
        <b-col md="2" class="my-1">
          <!-- add new admin button -->
          <div class="d-flex align-items-center">
            <b-button
              style="height: 2.142rem; line-height: 0.5"
              v-if="authPermissions.includes('create-order')"
              variant="primary"
              :to="{ path: '/orders/Create' }"
            >
              <span class="text-nowrap">Add Order</span>
            </b-button>
          </div>
        </b-col>

        <!-- actions -->
        <b-col cols="12 ">
          <b-table
            id="OrdersTable"
            ref="admint"
            striped
            responsive
            :items="items"
            :fields="fields"
            class="position-relative"
            @row-clicked="orderQuickView"
          >
            <template #cell(desc)="data">
              <b-card-text><div v-html="data.value"></div></b-card-text>
            </template>
            <template #cell(username)="items">
              <router-link
                :to="{ path: '/User/Show/' + items.item.user.id }"
                v-if="authPermissions.includes('user')"
              >
                {{ items.item.username }}
              </router-link>
              <p v-else>{{ items.item.username }}</p>
            </template>
            <template #cell(orderstatus)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(is_portal)="data">
              <b-badge variant="info" v-if="data.item.type == 'pos'">
                Cash On Site
              </b-badge>
              <b-badge variant="info" v-else-if="data.value"> Portal </b-badge>
              <b-badge variant="info" v-else> In Store </b-badge>
            </template>

            <!-- <template #cell(type)="data">
              <b-badge variant="light-success" v-if="data.item.remaining_amount != 0">partial cod</b-badge>
              {{ data.value }}
              
           
            </template> -->
            <template #cell(type)="data">
              <b-badge variant="light-success" v-if="data.item.remaining_amount != 0">partial cod</b-badge>
           
              
              <b-img-lazy
                v-if="data.item.transaction && data.item.transaction.payment_provider"
                width="40"
                :src="data.item.transaction.payment_provider.logo"
              />
              <p v-else> {{ data.value }}</p>
         </template>

            <template #cell(actions)="items">
              <div class="d-flex p-1">
                <!-- Show Order -->
                <b-button
                  v-if="authPermissions.includes('show')"
                  variant="success"
                  type="submit"
                  class="my-1 mx-0 mr-1 py-1 px-1"
                  :to="{ path: '/orders/show/' + items.item.id }"
                >
                  <feather-icon
                    style="width: 18px; height: 18px"
                    class="d-flex align-center"
                    icon="EyeIcon"
                  />
                </b-button>
                <!-- update Order -->
                <b-button
                  v-if="authPermissions.includes('update-order')"
                  class="my-1 mx-0 mr-1 py-1 px-1"
                  variant="warning"
                  type="submit"
                  :to="{ path: '/orders/Update/' + items.item.id }"
                >
                  <feather-icon
                    icon="EditIcon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
                <!-- update Order Status -->
                <b-button
                  v-if="authPermissions.includes('update-order-status')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="my-1 mx-0 mr-1 py-1 px-1"
                  variant="info"
                  type="submit"
                  :to="{ path: '/orders/Update/Status/' + items.item.id }"
                >
                  change Status
                </b-button>
                <!-- delete Order -->
                <b-button
                  v-if="authPermissions.includes('delete')"
                  class="my-1 mx-0 py-1 px-1"
                  variant="danger"
                  type="submit"
                  @click="deleteData(items.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>

    <!-- Order Details in table for variant -->
    <b-modal
      id="order-quick-view-modal"
      hide-footer
      ref="modal"
      centered
      title="Order Details"
      size="lg"
    >
      <b-row v-if="orderModal">
        <b-col md="6">
          <p class="text-primary h4 mt-1">
            Code: <span class="h5">{{ orderModal.code }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Status:
            <b-badge variant="primary">{{ orderModal.orderstatus }}</b-badge>
          </p>
          <p class="text-primary h4 mt-1">
            Total: <span class="h5">{{ orderModal.total_paid }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Type:
            <b-badge variant="warning">{{ orderModal.type }}</b-badge>
          </p>
          <p class="text-primary h4 mt-1">
            Payment Provider:
            <b-badge variant="info">{{ orderModal.payment_provider }}</b-badge>
          </p>
          <p class="text-primary h4 mt-1" v-if="orderModal.transaction">
            Transaction Ref:
            <router-link
              :to="{ path: '/transaction/show/' + orderModal.transaction.id }"
            >
              <b-badge variant="info">{{
                orderModal.transaction.transaction_ref
              }}</b-badge>
            </router-link>
          </p>
        </b-col>
        <b-col md="6">
          <p class="text-primary h4 mt-1">
            Shipping Price:
            <span class="h5">{{ orderModal.shipping_price }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Vendor:
            <span class="h5">{{ orderModal.vendor_storename }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            <b-badge variant="secondary">User Data</b-badge>
          </p>
          <p class="text-primary h4 mt-1">
            Username: <span class="h5">{{ orderModal.username }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Email: <span class="h5">{{ orderModal.user_email }}</span>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="orderModal.variants" class="my-2">
        <b-col md="12">
          <p class="text-secondary h4 mt-1">Items</p>
          <table class="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Quantity</th>
                <th scope="col">margin</th>
                <th scope="col">Price</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(variant, index) in orderModal.variants" :key="index">
                <td dir="rtl">{{ variant.name }}</td>
                <td>
                  <b-badge :variant="variant.is_new ? 'success' : 'info'">
                    {{ variant.is_new ? 'New' : 'Used' }}
                  </b-badge>
                </td>
                <td style="text-align: center">{{ variant.quantity }}</td>
                <td>{{ variant.margin }}</td>
                <td>{{ variant.price }}</td>
                <td>
                  <b-button
                    style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                    class="btn-icon rounded-circle"
                    :to="{
                      path:
                        (variant.is_new ? '/New' : '/Used') +
                        '/ShowProduct/variant/show/' +
                        variant.id,
                    }"
                    variant="success"
                  >
                    <feather-icon
                      style="width: 18px; height: 18px"
                      icon="EyeIcon"
                    />
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row v-if="orderModal.transaction" class="my-2">
        <b-col md="12">
          <p class="text-secondary h4 mt-1">Transaction</p>
          <table class="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Transaction Ref</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">Status</th>
                <th scope="col">Amount</th>
                <th scope="col">Remaining Amount</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td dir="rtl">{{ orderModal.transaction.transaction_ref }}</td>
                <td style="text-align: center">
                  {{ orderModal.transaction.transaction_type }}
                </td>
                <td>{{ orderModal.transaction.status }}</td>
                <td>{{ orderModal.transaction.amount }}</td>
                <td>{{ orderModal.transaction.remaining_amount }}</td>
                <td>
                  <b-button
                    style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                    class="btn-icon rounded-circle"
                    :to="{ path: '/transaction/show/' + orderModal.transaction.id }"
                    variant="success"
                  >
                    <feather-icon
                      style="width: 18px; height: 18px"
                      icon="EyeIcon"
                    />
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <!-- <validation-observer ref="simpleRules">
        <form ref="form" @submit.stop.prevent="handleSubmitUpdatePrice">
          <b-row>
            <b-col md="3">
              <b-form-group>
                <label class="h6 text-gray">Vendor Payout</label>
                <validation-provider
                  #default="{ errors }"
                  name="Vendor Payout"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    @input="vendorPayoutClac"
                    v-model="varaintDataPrice.vendorPayout"
                    min="0"
                    class="text-center"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
            <b-col md="3"
              ><b-form-group>
                <label class="h6 text-gray">Rate in %</label>
                <validation-provider
                  #default="{ errors }"
                  name="Rate in %"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    @input="rateInPercentClac"
                    v-model="varaintDataPrice.rateInPercent"
                    min="0"
                    class="text-center"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
            <b-col md="3">
              <b-form-group>
                <label class="h6 text-gray">Rate in EGP</label>
                <validation-provider
                  #default="{ errors }"
                  name="Rate in EGP"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    @input="rateClac"
                    v-model="varaintDataPrice.rateEGP"
                    min="0"
                    class="text-center"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
            <b-col md="3">
              <b-form-group>
                <label class="h6 text-gray">Protal Price</label>
                <validation-provider
                  #default="{ errors }"
                  name="Protal Price"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    @input="protalPriceClac"
                    v-model="varaintDataPrice.portalPrice"
                    min="0"
                    class="text-center"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
            <b-col cols="12">
              <b-button
                variant="warning"
                @click="handleSubmitUpdatePrice()"
                v-if="authPermissions.includes('update-product-status')"
                >Update</b-button
              >
            </b-col>
          </b-row>
        </form>
      </validation-observer> -->
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,

      sortBy: 'created_at',
      status: 'all',
      statusName: 'All',
      classificationName: 'All',
      classification: 'all',
      orderClassification: [
        {
          name: 'Portal',
          slug: 'portal',
        },
        {
          name: 'Cash On Site',
          slug: 'pos',
        },
        {
          name: 'In Store',
          slug: 'in_store',
        },
      ],
      orderStatus: [],
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'is_portal',
          label: 'Sales Channel',
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'orderstatus',
          label: 'Order Status',
        },
        {
          key: 'total_paid',
          label: 'Total',
        },
        {
          key: 'type',
          label: 'Type',
        },
        // {
        //   key: 'payment_provider',
        //   label: 'Payment',
        // },
        {
          key: 'shipping_price',
          label: 'Shipping Price',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      exportItems: [],
      orderModal: {},
    }
  },
  created() {
    this.fetchData();
    this.authPermissions = this.$store.state.authPermissions
    // request Orders index

    axios
      .get('order-status')
      .then((result) => {
        this.orderStatus = result.data.data
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    status: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    classification: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      // if (this.filter.length >= 3 || this.filter.length == 0) {
      //   this.currentPage = 1
      //   if (this.sortDesc == true) {
      //     this.sortDirection = 'desc'
      //   } else {
      //     this.sortDirection = 'asc'
      //   }
      //   this.fetchData()
      // }
      if (this.filter.length == 0 || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    // fetch data of Orders
    fetchData() {
      var url = null
      if (this.filter == '') {
        url =
          'orders/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.status +
          '/' +
          this.classification +
          '?page=' +
          this.currentPage
      } else {
        url =
          'orders/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.status +
          '/' +
          this.classification +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          this.items = result.data.data.data
          //console.log(this.items)
          this.exportItems = this.items
          this.$refs.admint.refresh()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    // delete function of (delete button) Order
    deleteData(id) {
      //console.log('delete')
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('orders/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    orderQuickView(item, index, event) {
      this.$bvModal.show('order-quick-view-modal')
      this.orderModal = item
      //console.log(this.orderModal)
      // //console.log(index)
      // //console.log(event)
    },
    changeStatus(name, slug) {
      this.status = slug
      this.statusName = name
    },
    changeClassification(name, slug) {
      this.classification = slug
      this.classificationName = name
    },
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].username,
          this.items[index].code,
          this.items[index].orderstatus,
          this.items[index].totla,
          this.items[index].discount,
          this.items[index].shipping_price,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [
          [
            'ID',
            'User Name',
            'Code',
            'Order Status',
            'Totla',
            'Discount',
            'Shipping Price',
            'CREATED_AT',
            'UPDATED_AT',
          ],
        ],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style>
.orders-table {
  max-height: 70vh !important;
}
</style>
